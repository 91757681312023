<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="900"
    scrollable
    persistent
    @keydown.esc="goToClientsPage"
  >
    <ClientForm
      :client="newClient"
      :errors="clientValidationErrors"
      :disabled="$store.getters.loading['post:api/clients']"
      @clear:errors="clearClientValidationErrors"
      @cancel="goToClientsPage"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import ClientForm from '@/components/ClientForm';

export default {
  name: 'CreateClient',

  components: { ClientForm },

  computed: mapState('clients', ['newClient', 'clientValidationErrors', 'clientFilterParams']),

  created() {
    this.RESET_CLIENT_VALIDATION_ERRORS();
  },

  methods: {
    ...mapActions('clients', ['storeClient', 'clearClientValidationErrors']),
    ...mapMutations('clients', ['RESET_CLIENT_VALIDATION_ERRORS']),

    onSave(client) {
      this.storeClient(client).then(() => {
        this.goToClientsPage();
      });
    },

    goToClientsPage() {
      this.$router.push({ name: 'clients', query: this.clientFilterParams }).catch(() => {});
    },
  },
};
</script>

<style scoped></style>
